import { careersTypes } from '../types/careers';

const {
  GET_CAREERS_STARTED,
  GET_CAREERS_FAILURE,
  GET_CAREERS_SUCCESS,
} = careersTypes;

const initialState = {
  loading: false,
  careers: [],
  error: null,
};

export function careersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CAREERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_CAREERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        careers: action.payload,
      };
    case GET_CAREERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
