import React from 'react';

import Header from '../../../img/Career/Header.png';

import data from '../constants';

const CareerContainer = () => (
  <div className="containerM" style={{ width: '95%', marginTop: '100px' }}>
    <div className="career-inner">
      <h2 className="career-inner-header">Вакансии</h2>
      <div className="career-inner-content">
        <img className="career-img" src={Header} alt="about_us" />
      </div>
      <section className="career-inner-content">
        <div className="text">
          <h2 className="career_content_header">Требования к соискателям</h2>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>01. Преверженность профессии.</h6>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>02. Наличие опыта - для специалистов со стажем.</h6>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>03. Молодым специалистам к заявке о рассмотрении вакансии приложить эссе на тему: "Почему я хочу работать в этой профессии, мои амбиции цели и амбиции в профессиональной деятельности"</h6>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>04. Архитекторам - портфолио обязательно.</h6>
          </div>
        </div>
        <p>К сотрудничеству приглашаются как отдельные специалисты так и уже сформированные команды по смежным специальностям: конструкции, инженерные системы, дизайн, благоустройство и ландшафтный дизайн, генплан, сметы.</p>
        <p>Даже если на момент подачи заявки в организации не будет интересующей Вас вакансии мы добавим Вас в базу и обратимся к Вам, когда появится интересующая Вас работа.</p>
      </section>
    </div>
  </div>
);

export default CareerContainer;
