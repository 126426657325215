export const pTypes = [
  {
    title: 'julie-complexy-i-doma',
    img: 'https://storage.googleapis.com/atta-static/house.jpg.jpeg',
    category: 1
  },
  {
    title: 'master-plany-i-concepcii',
    img: 'https://storage.googleapis.com/atta-static/house.jpg.jpeg',
    category: 2
  },
  {
    title: 'business-centres',
    img: 'https://storage.googleapis.com/atta-static/hotel.jpg.jpeg',
    category: 3
  },
  {
    title: 'torgovo-razvlekatelnie-centry',
    img:
      'https://storage.googleapis.com/atta-static/business.jpg1636899741858.jpeg',
    category: 4
  },
  {
    title: 'mnogofunkcionalnie-complexy',
    img: 'https://storage.googleapis.com/atta-static/complex.png.jpeg',
    category: 5
  },
  {
    title: 'objecty-cultury-i-vystavochnie-centry',
    img: 'https://storage.googleapis.com/atta-static/culture.jpg.jpeg',
    category: 6
  },
  {
    title: 'objecty-zdravoohranenia',
    img: 'https://storage.googleapis.com/atta-static/health.JPG.jpeg',
    category: 7
  },
  {
    title: 'physkulturno-ozdorovitelnie-objecty-spa',
    img: 'https://storage.googleapis.com/atta-static/spa.jpg.jpeg',
    category: 8
  },
  {
    title: 'gostinnici-apartamenty',
    img: 'https://storage.googleapis.com/atta-static/hotel.jpg.jpeg',
    category: 9
  },
  {
    title: 'objecty-transportnoy-infrastructury',
    img: 'https://storage.googleapis.com/atta-static/airoport.jpg.jpeg',
    category: 10
  },
  {
    title: 'landshaft-i-blagoustroystvo',
    img: 'https://storage.googleapis.com/atta-static/land.jpg.jpeg',
    category: 11
  },
  {
    title: 'diplomaticheskie-predstavitelstva-za-rubezhom',
    img: 'https://storage.googleapis.com/atta-static/space.JPG.jpeg',
    category: 13
  },
  {
    title: 'organizacia-prostranstva-interieru',
    img: 'https://storage.googleapis.com/atta-static/space.JPG.jpeg',
    category: 12
  },
];
