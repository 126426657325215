import React, { useState } from 'react';
import { ProjectType } from './ProjectType';
import { getCategories } from '../../../data/useCategories';

export const ProjectsList = () => {
  const categoriesData = getCategories()
  const [types, setTypes] = useState(categoriesData);

  const renderCategories = () => {
    return types
      .sort((a, b) => a.position - b.position)
      .map((type, index) => <ProjectType type={type} index={index} />);
  };

  return (
    <div className="projects-list">
      {renderCategories()}
    </div>
  );
};
