import React, { useState, useEffect } from 'react';
import { debounce } from './debounce';
import {
  CCollapse,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavbar,
  CNavbarNav,
  CNavLink,
  CToggler,
} from '@coreui/react';
import logo from '../../img/logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export const Navbar = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [lan, setLan] = useState('RU');

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos < 80) {
      return setVisible(true);
    }

    setVisible(prevScrollPos > currentScrollPos);

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const isMain = () => {
    return pathname === '/'
  }

  return (
    <div
      style={{
        top: visible ? '0' : '-100px',
        backgroundColor: isOpen ? 'white' : 'rgba(0,0,0,0)',
        background: isMain() ? 'rgba(0, 0, 0, 0.5)' : 'white',
        border: isMain() ? 'none' : '1px solid #E3E3E3',
      }}
      className={`nbar ${visible && 'nbar-show'}`}
    >
      <div style={{ height: '100%', width: '95%', margin: '0 auto' }}>
        <CNavbar expandable="sm" light={isMain()} className={isMain() ? 'navibarMain' : 'navibar'} style={{ height: '100%', padding: 0 }}>
          <img
            src={logo}
            style={{ maxHeight: '70px', maxWidth: '105px', cursor: 'pointer' }}
            alt="atta-group"
            onClick={() => history.push('/')}
          />
          <CToggler
            inNavbar
            onClick={() => setIsOpen(!isOpen)}
            style={{ color: 'black', marginRight: '2rem', backgroundColor: '#b0d76d' }}
          />
          <Container
            fluid
            style={{
              transition: 'all 0.5s ease-in-out',
              paddingRight: '0'
            }}
          >
            <CCollapse
              show={isOpen}
              navbar
              style={{
                height: isOpen ? '100vh' : '0px',
                backgroundColor: isOpen ? 'white' : 'rgba(0,0,0,0)',
              }}
            >
              <CNavbarNav style={{ marginLeft: '1.5rem' }}>
                <CNavLink>
                  <Link
                    to="/projects"
                    className="navibar-menu-item"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="navibar-menu-item-title" style={{

                    }}>
                      <span style={{ color: isMain() ? (isOpen ? 'black' : 'whitesmoke'): 'black' }}>Проекты</span>
                    </div>
                  </Link>
                </CNavLink>
                <CNavLink>
                  <Link
                    to="/news"
                    className="navibar-menu-item"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="navibar-menu-item-title">
                      <span style={{ color: isMain() ? (isOpen ? 'black' : 'whitesmoke'): 'black' }}>Новости</span>
                    </div>
                  </Link>
                </CNavLink>
                <CNavLink>
                  <Link
                    to="/career"
                    className="navibar-menu-item"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="navibar-menu-item-title">
                      <span style={{ color: isMain() ? (isOpen ? 'black' : 'whitesmoke'): 'black' }}>Вакансии</span>
                    </div>
                  </Link>
                </CNavLink>
                <CNavLink>
                  <Link
                    to="/contacts"
                    className="navibar-menu-item"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="navibar-menu-item-title">
                      <span style={{ color: isMain() ? (isOpen ? 'black' : 'whitesmoke'): 'black' }}>Контакты</span>
                    </div>
                  </Link>
                </CNavLink>
                <CNavLink>
                  <Link
                    to="/about"
                    className="navibar-menu-item"
                    onClick={() => setIsOpen(false)}
                  >
                    <div className="navibar-menu-item-title">
                      <span style={{ color: isMain() ? (isOpen ? 'black' : 'whitesmoke'): 'black' }}>О нас</span>
                    </div>
                  </Link>
                </CNavLink>
              </CNavbarNav>
            </CCollapse>
          </Container>
        </CNavbar>
      </div>
    </div>
  );
};
