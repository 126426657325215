import React from 'react';
import location from '../../../img/location.PNG';
import loca from '../../../img/loca.png'
import phone from '../../../img/phone.png'
import email from '../../../img/email.png'

export const ContactsContainer = () => (
  <div className="contacts">
    <div className="contacts-container">
      <div className="contacts-container-data">
        <h2 className="contacts-container-title">Контакты</h2>
        <h5 style={{ marginBottom: '1rem' }}>
          <b>Группа Компаний "ATTA"</b>
        </h5>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={loca} alt='Местоположение' style={{ height: '25px', width: '25px', marginRight: '0.5rem' }}/>
          <span style={{ fontSize: '1rem' }}>Россия Новосибирск, 630007 ул. Октябрьская магистраль 4, офис 1601</span>
        </div>
        <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          <img src={phone} alt='Телефон' style={{ height: '25px', width: '25px', marginRight: '0.5rem' }}/>
          <span>тел./факс +7(383)223-22-99</span>
        </div>
        <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          <img src={phone} alt='Телефон' style={{ height: '25px', width: '25px', marginRight: '0.5rem' }}/>
          <span>тел./факс +7(383)223-29-90</span>
          </div>
        <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          <img src={phone} alt='Телефон' style={{ height: '25px', width: '25px', marginRight: '0.5rem' }}/>
          <span>тел./факс +7(383)223-29-99</span>
        </div>
        <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          <img src={email} alt='Почта' style={{ height: '25px', width: '25px', marginRight: '0.5rem' }}/>
          <a
            href="mailto:atta@atta-group.ru"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            atta@atta-group.ru
          </a>
        </div>
      </div>
    </div>
    <div className="contacts-container-location">
      <img src={location} alt="location" />
    </div>
  </div>
);
