import React from 'react';
import { useHistory } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useImage } from '../../../data/useImage';
export const ProjectType = ({ type }) => {
  const history = useHistory();
  const { image } = useImage(type.img)

  return (
    <div className="project">
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animateOnce={true}
        style={{ overflow: 'hidden' }}
      >
        <div
          className="project-item"
          style={{
            background: `url(${image}) center center / cover no-repeat`,
            backgroundSize: 'cover',
          }}
          onClick={() => {
            history.push(`/projects/${type._id.$oid}`);
          }}
          title={type.title}
        >
          <span className="project-item-title">
            <span>{type.title}</span>
          </span>
        </div>
      </AnimationOnScroll>
    </div>
  );
};
