import stats from '../../../img/About/stat';

const data = [
  {
    title: 'Проектов',
    icon: stats[0],
    class: 'stats-inner-item-img-wrap stat-img-wrap1',
  },
  {
    title: 'Объектов',
    icon: stats[1],
    class: 'stats-inner-item-img-wrap stat-img-wrap2',
  },
  {
    title: 'Наград',
    icon: stats[2],
    class: 'stats-inner-item-img-wrap stat-img-wrap3',
  },
  {
    title: 'Региона РФ',
    icon: stats[3],
    class: 'stats-inner-item-img-wrap stat-img-wrap4',
  },
];

export default data;
