import React from 'react';
import { FilteredProjectsList } from '../components/FilteredProjectsList';

export const FilteredProjectsContainer = ({ category }) => {
  const map = {
    'Жилые комплексы и дома': 1,
    'Мастер-планы и концепции': 2,
    'Бизнес-центры': 3,
    'Торгово-развлекательные центры': 4,
    'Многофункциональные комплексы': 5,
    'Объекты культуры и выставочные центры': 6,
    'Объекты здравоохранения': 7,
    'Физкультурно-оздоровительные объекты | СПА': 8,
    'Гостиницы | Апартаменты': 9,
    'Объекты транспортной инфраструктуры': 10,
    'Ландшафт и благоустройство': 11,
    'Организация пространства, интерьеры': 12,
    'Дипломатические представительства РФ за рубежом': 13,
  };

  return <FilteredProjectsList category={map[category]} />;
};
