import React from 'react';
import { NewsList } from '../components/NewsList';

export const NewsContainer = ({ news }) => {
  return (
    <div>
      <NewsList news={news} />
    </div>
  );
};
