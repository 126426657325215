import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useHistory } from 'react-router-dom';
import { useImage } from '../../../data/useImage';
import { Loader } from '../../../components/loading/Loader';

export const News = ({ article }) => {
  const history = useHistory();
  const style = { fontSize: '0.8em', fontWeight: 'bold' };
  const { loading, error, image } = useImage(article.header)

  const readMore = () => {
    const text = (article.short.length > 150 ? `${article.short.slice(0, 150)}...` : article.short) || ''
    return <>
      {text}
    </>
  }

  return (
    <div className="article">
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        animateOnce={true}
        style={{ overflow: 'hidden' }}
      >
        <div
          className="article-item"
          style={{
            backgroundSize: 'cover',
            background: `url(${image}) center center / cover no-repeat`,
          }}
          onClick={() => {
            history.push(`/news/${article._id['$oid']}`);
          }}
        >
          {loading ? <Loader /> : <span className="article-item-title">
            <span>
              <p style={{ marginBottom: '0', fontSize: '16px', color: 'lightgray' }}>{article.date}</p>
              <h6 style={article.title.length > 40 ? style : { fontWeight: 'bold' }}>{article.title}</h6>
              <p style={{ fontSize: '16px' }} className="article-info-short">{readMore()}</p>
            </span>
          </span>}
        </div>
      </AnimationOnScroll>
    </div>
  );
};
