import projects from './attaprojects.json'

export const getProjects = (id) => {
  return projects.map(item => ({ ...item, images:
      { header: item.images?.header?.split('https://storage.googleapis.com/atta-static/')[1],
        body: item.images?.body?.split('https://storage.googleapis.com/atta-static/')[1]
      }
  }))
    .filter(item => +item.category.$numberInt === id)
}

export const getProject = async (id) => {
  const result = projects.find(item => item._id.$oid === id)

  return {...result, images: {
      header: result.images?.header?.split('https://storage.googleapis.com/atta-static/')[1],
      body: result.images?.body?.split('https://storage.googleapis.com/atta-static/')[1],
      slider: result?.images?.slider?.map(item => item.split('https://storage.googleapis.com/atta-static/')[1])
      }}
}