import React, { useState } from 'react';

import {
  CCarousel,
  CCarouselInner,
  CCarouselIndicators,
  CCarouselControl,
} from '@coreui/react';

import awards from '../constants/awards';
import { arrayToChunk } from '../../Projects/components/arrayToChunk';
import { AwardItemComponent } from './AwardItem.component';

export const Awards = () => {
  const [activeAward, setActiveAward] = useState(awards[0]);
  const chunkAwards = arrayToChunk(awards, 3)


  const select = (index) => {
    setActiveAward(index);
  };

  return (
    <>
      <div className="awards-inner-items">
        <CCarousel className="carousel-atta" activeIndex={0} animate={true}>
          <div>
            <CCarouselInner className="carousel-atta-inner">
              {chunkAwards.map((item) =>  (
                <AwardItemComponent item={item}/>
              ))}
            </CCarouselInner>
          </div>
          <CCarouselControl
            direction="prev"
            className="carousel-atta-control"
          />
          <div className="carousel-atta-controls">
            <CCarouselControl
              direction="next"
              className="carousel-atta-control"
            />
          </div>
          <div className="indicators-container-about">
            <CCarouselIndicators className="carousel-atta-indicators" />
          </div>
        </CCarousel>
      </div>
    </>
  );
};

export default Awards;
