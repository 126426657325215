import award1 from './architectureaward.jpg';
import award3 from './businessaward.jpg';
import award4 from './diplom.jpg';
import award5 from './diplomatta.jpg';
import award6 from './EBABESTENTERPRISE.jpg';
import award7 from './EngineersofNEWERA.jpg';
import award8 from './euberlin.jpg';
import award9 from './eustandart.jpg';
import award10 from './newprize.jpg';
import award11 from './swisspartners.jpg'

const AwArray = [award6, award7, award4, award5, award9, award8, award3, award1, award10, award11];

export default AwArray;