import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../../components/footer/Footer.component';
import { FilteredProjectsContainer } from '../../modules/Projects/containers/FilteredProjectsContainer';

const Projects = () => {
  const { category } = useParams();

  const map = {
    'julie-complexy-i-doma': 'Жилые комплексы и дома',
    'master-plany-i-concepcii':'Мастер-планы и концепции',
    'business-centres': 'Бизнес-центры',
    'torgovo-razvlekatelnie-centry':'Торгово-развлекательные центры',
    'mnogofunkcionalnie-complexy': 'Многофункциональные комплексы',
    'objecty-cultury-i-vystavochnie-centry': 'Объекты культуры и выставочные центры',
    'objecty-zdravoohranenia': 'Объекты здравоохранения',
    'physkulturno-ozdorovitelnie-objecty-spa': 'Физкультурно-оздоровительные объекты | СПА',
    'gostinnici-apartamenty': 'Гостиницы | Апартаменты',
    'objecty-transportnoy-infrastructury': 'Объекты транспортной инфраструктуры',
    'landshaft-i-blagoustroystvo': 'Ландшафт и благоустройство',
    'organizacia-prostranstva-interieru': 'Организация пространства, интерьеры',
    'diplomaticheskie-predstavitelstva-za-rubezhom': 'Дипломатические представительства РФ за рубежом',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="projects">
        <div className="containerM">
          <div className="projects_inner" style={{ width: '95%', margin: '0 auto' }}>
            <h2 className="header_title">{map[category]}</h2>
            <div className="projects-container">
              <FilteredProjectsContainer category={category} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Projects;
