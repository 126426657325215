import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NewsContainer } from '../../modules/News/containers/NewsContainer';
import Footer from '../../components/footer/Footer.component';
import { getNews } from '../../services';
import { Loader } from '../../components/loading/Loader';

import newsData from '../../data/attanews.json'

const News = () => {
  const mappedNews = newsData
    .map(item => ({ ...item, header: item.header.split('https://storage.googleapis.com/atta-static/')[1] }))
    .sort((a,b) => new Date(b.date) - new Date(a.date))
  const [news, setNews] = useState(mappedNews);

  return (
    <>
      <div className="news">
        <div className="containerM" style={{ width: '100%' }}>
          <div className="news_inner" style={{ width: '95%' }}>
            <h2 className="header_title">Новости</h2>
            <div className="content">
              {news.length < 1 ? (
                <Loader />
              ) : (
                <NewsContainer news={news} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default News;
