import React from 'react';
import { Link } from 'react-router-dom';
import instagram from '../../img/instaicon.png'
import pinterest from '../../img/pinteresticon.png'
import youtube from '../../img/youtubeicon.png'

const Footer = () => (
  <div className="footer">
    <div className="containerM">
      <div className="footer_inner" style={{ width: '95%' }}>
        <div className="footer_projects">
          <h3 className="footer-header">Проекты</h3>
          <div className="footer_links">
            <Link
              to="/projects/master-plany-i-concepcii"
              className="footer_link-item"
            >
              Мастер-планы и концепции
            </Link>
            <Link
              to="/projects/julie-complexy-i-doma"
              className="footer_link-item"
            >
              Жилые комплексы и дома
            </Link>
            <Link to="/projects/business-centres" className="footer_link-item">
              Бизнес-центры
            </Link>
            <Link
              to="/projects/torgovo-razvlekatelnie-centry"
              className="footer_link-item"
            >
              Торгово-развлекательные центры
            </Link>
            <Link
              to="/projects/mnogofunkcionalnie-complexy"
              className="footer_link-item"
            >
              Многофункциональные комплексы
            </Link>
          </div>
          <div className="footer_links">
            <Link
              to="/projects/objecty-cultury-i-vystavochnie-centry"
              className="footer_link-item"
            >
              Объекты культуры
            </Link>
            <Link
              to="/projects/objecty-zdravoohranenia"
              className="footer_link-item"
            >
              Объекты здравоохранения
            </Link>
            <Link
              to="/projects/physkulturno-ozdorovitelnie-objecty-spa"
              className="footer_link-item"
            >
              Физкультурно-оздоровительные объекты/СПА
            </Link>
            <Link
              to="/projects/gostinnici-apartamenty"
              className="footer_link-item"
            >
              Гостиницы/Апартаменты
            </Link>
            <Link
              to="/projects/objecty-transportnoy-infrastructury"
              className="footer_link-item"
            >
              Объекты транспортной инфраструктуры
            </Link>
          </div>
          <div className="footer_links">
            <Link
              to="/projects/organizacia-prostranstva-interieru"
              className="footer_link-item"
            >
              Организация пространства, интерьеры
            </Link>
            <Link
              to="/projects/landshaft-i-blagoustroystvo"
              className="footer_link-item"
            >
              Ландшафт и благоустройство
            </Link>
            <Link
              to="/projects/diplomaticheskie-predstavitelstva-za-rubezhom"
              className="footer_link-item"
            >
              Дипломатические представительства РФ за рубежом
            </Link>
          </div>
        </div>
        <div className="footer_nav">
          <h3 className="footer-header">Компания</h3>
          <div className="footer_links">
            <Link to="/news" className="footer_link-item">
              Новости
            </Link>
            <Link to="/career" className="footer_link-item">
              Вакансии
            </Link>
            <Link to="/about" className="footer_link-item">
              О нас
            </Link>
            <Link to="/contacts" className="footer_link-item">
              Контакты
            </Link>
            <div style={{ display: 'flex', float: 'right', justifyContent: 'space-evenly', width: '12%' }}>
              <a href='https://www.youtube.com/channel/UCBzRYn3pKru7JBdoYcLBAxw' target="_blank">
                <img src={youtube} alt='youtube' style={{ height: '25px', width: '25px' }}/>
              </a>
              <a href='https://www.pinterest.ru/attagroup/' target="_blank">
                <img src={pinterest} alt='pinterest' style={{ height: '25px', width: '25px' }}/>
              </a>
            </div>
            <div style={{ marginTop: '1rem', 'user-select': 'none' }}>
              <span style={{ fontSize: '10px', color: '#9B9B9B' }}>ATTA GROUP &#169; 1987 - 2022</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
