import { CCarouselCaption } from '@coreui/react';
import React from 'react';
import { useImage } from '../../data/useImage';

export const Slide = ({slide}) => {
  const { image } = useImage(slide.images.header)
  return (
    <div className="slider-item">
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 80%)',
        }}
      />
      <div
        style={{
          backgroundImage: `url(${image})`,
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />
      <CCarouselCaption className="d-none d-md-block slider-caption">
        <a
          href={`projects/project/${slide._id}`}
          style={{ color: 'white', textDecoration: 'none' }}
        >
          <h4>{slide.name}</h4>
        </a>
      </CCarouselCaption>
    </div>
  )
}