import { useParams } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Footer from '../../../components/footer/Footer.component';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Loader } from '../../../components/loading/Loader';
import { getArticle, getLatestArticles } from '../../../data/useArticle';
import { useImage } from '../../../data/useImage';
import { CCarousel, CCarouselIndicators, CCarouselInner, CCarouselItem } from '@coreui/react';
import { LatestArticle } from './LatestArticle';

export const Article = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState({});
  const [otherArticles, setOtherArticles] = useState([]);

  const { loading, error, image } = useImage(article?.header?.split('https://storage.googleapis.com/atta-static/')[1])

  useEffect(() => {
    const found = getArticle(articleId)
    setArticle(found)
    const latest = getLatestArticles(found._id.$oid)
    setOtherArticles(latest)

    return () => {
      setArticle({})
    }
  }, [articleId]);

  return (
    <div>
    <div className="article-details">
      {article?._id?.$oid ? (
        <>
          <div className="article-details-header">
            <img src={image} alt="header" />
            <span className="article-details-container">
              <span className="article-details-container-name">
                {article.title}
              </span>
            </span>
          </div>
          <div className="article-details-info">
            <div className="project-details-image">
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                animateOnce={true}
              >
                <div className="project-details-image-text">
                  <p style={{ marginBottom: '0', fontSize: '1rem', color: 'lightgray', textAlign: 'center' }}>{article.date}</p>
                  <ReactQuill
                    theme="snow"
                    value={article.description}
                    modules={{ toolbar: false }}
                    className="quillEditor"
                    readOnly
                  />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="article-details-other">
            <div className="article-details-other-title">
              <span>Другие новости</span>
            </div>
            <CCarousel animate={true}>
              <CCarouselInner className="article-details-slider-inner">
                {otherArticles.map(article =>
                  (<CCarouselItem>
                    <LatestArticle article={article} />
                  </CCarouselItem>)
                )}
              </CCarouselInner>
              <div className="indicators-container">
                <CCarouselIndicators className="carousel-atta-indicators-news" />
              </div>
            </CCarousel>
          </div>
        </>
      ) : (
        <><Loader /></>
      )}
    </div>
  <Footer />
  </div>
  );
};
