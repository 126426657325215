import attanews from './attanews.json'

export const chunkArray = (arr, size = 2) => {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/size)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export const getArticle = (id) => {
  return attanews.find(item => item._id["$oid"] === id)
}

export const getLatestArticles = (id) => {
  const latest = attanews
    .sort((a,b) => new Date(b.date) - new Date(a.date))
    .filter(item => item._id.$oid !== id)
    .map(item => ({...item, header: item.header.split('https://storage.googleapis.com/atta-static/')[1]}))

  return chunkArray(latest, 2)
}