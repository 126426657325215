import { CSpinner } from '@coreui/react';
import React from 'react';

export const Loader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        top: '0',
        left: '0',
        width: '100%',
        zIndex: '999',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
      >
        <CSpinner style={{ color: '#b0d76d' }} size="lg" />
      </div>
    </div>
  );
};
