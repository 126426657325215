import React from 'react';

import Header from '../../../img/About/attamap.jpg';
import Stats from './AboutStats.components';
import Awards from './AboutAwards.component';

import data from '../constants';
import ReactPlayer from 'react-player';

const AboutContainer = () => (
  <div className="containerM">
    <div className="about-inner">
      <h2 className="about-inner-header">О нас</h2>
      <div className="about-inner-content" style={{ width: '100%', margin: '0 auto' }}>
        <img className="about_us-img" src={Header} alt="about_us" />
      </div>
      <div className="about-inner-content">
        <div className="stats-inner">
          <Stats />
        </div>
      </div>
      <section className="about-inner-content">
        <div className="text">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>01.</h6>
            <span className="about_content_text">{data.text_p1}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>02.</h6>
            <span className="about_content_text">{data.text_p2}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>03.</h6>
            <span className="about_content_text">{data.text_p3}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>04.</h6>
            <span className="about_content_text">{data.text_p4}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>05.</h6>
            <span className="about_content_text">{data.text_p5}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>06.</h6>
            <span className="about_content_text">{data.text_p6}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>07.</h6>
            <span className="about_content_text">{data.text_p7}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <h6 style={{ fontWeight: 'bold', marginRight: '0.5rem', marginBottom: 0 }}>08.</h6>
            <span className="about_content_text">{data.text_p8}</span>
          </div>
        </div>
      </section>
      <section className="about-inner-content">
        <div className="text">
          <h2 className="about_content_header">Наши награды</h2>
        </div>
        <div className="awards-inner">
          <Awards />
        </div>
      </section>
      <section className="about_video">
        <div className="video">
          <div className="wrapper">
            <ReactPlayer
              width="100%"
              height="800px"
              url="https://youtu.be/2lR7Sk4e128"
              controls={true}
            />
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default AboutContainer;
