import React from 'react';
import stats from '../constants/stats';

const Stats = () =>
  stats.map((stat) => (
    <div className="stats-inner-item">
      <div className={stat.class}>
        <img src={stat.icon} alt="stat1" className="stat-img" />
      </div>
      <h2>{stat.title}</h2>
    </div>
  ));

export default Stats;
