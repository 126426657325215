import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import {
  CCarousel,
  CCarouselControl,
  CCarouselIndicators,
  CCarouselInner,
  CCarouselItem,
} from '@coreui/react';
import Footer from '../../../components/footer/Footer.component';
import ReactPlayer from 'react-player';
import { arrayToChunk } from './arrayToChunk';
import { Loader } from '../../../components/loading/Loader';
import { OtherProjects } from './OtherProjects';

import { getProject, getProjects } from '../../../data/useProjects';
import { useImage } from '../../../data/useImage';
import { ProjectSliderItem } from './ProjectSliderItem';

export const ProjectDetails = () => {
  const { name } = useParams()
  const [project, setProject] = useState({
    images: { header: '', body: '', slider: [] },
    description: {},
  });

  const [slider, setSlider] = useState([])
  const [cProjects, setCProjects] = useState([])
  const [left, setLeft] = useState('curtain__panel');
  const [right, setRight] = useState('curtain__panel');
  const header = useImage(project?.images?.header)
  const body = useImage(project?.images?.body)

  useEffect(() => {
    getProject(name).then((data) => {
      setProject(data)
      setSlider(data.images.slider)

      setTimeout(() => {
        setLeft('curtain__panel curtain__panel--left');
        setRight('curtain__panel curtain__panel--right');
      }, 300)
      const other = getProjects(+data?.category?.$numberInt)
      setCProjects(arrayToChunk(other, 3))
    })

    return () => {
      setSlider([])
      setProject({
        images: { header: '', body: '', slider: [] },
        description: {},
      })
    }
  }, [name]);

  return (
    project?._id?.$oid ? (
      <div>
      <div className="project-details">
        <div
          className="project-details-header"
          style={{
            backgroundImage: `url(${header.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="curtain">
            <div className="curtain__wrapper">
              <div className={left} />
              <div className="curtain__content" />
              <div className={right} />
            </div>
          </div>
          <span className="project-details-container">
          <span
            className="project-details-container-name"
            style={{ zIndex: '1' }}
          >
            {project.name}
          </span>
        </span>
        </div>
        <div className="project-info">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="project-details-info">
              <div className="project-details-info-item">
              <span className="project-details-info-item-title">
                Общая площадь
              </span>
                <span className="project-details-info-item-status">
                {project.area}
              </span>
              </div>
              <div className="project-details-info-item">
                <span className="project-details-info-item-title">Расположение</span>
                <span className="project-details-info-item-status">
                {project.height}
              </span>
              </div>
              <div className="project-details-info-item">
                <span className="project-details-info-item-title">Статус</span>
                <span className="project-details-info-item-status">
                {project.status}
              </span>
              </div>
              <div className="project-details-info-item">
              <span className="project-details-info-item-title">
                Начало проекта
              </span>
                <span className="project-details-info-item-status">
                {project.start}
              </span>
              </div>
              <div className="project-details-info-item">
              <span className="project-details-info-item-title">
                Окончание проекта
              </span>
                <span className="project-details-info-item-status">
                {project.end}
              </span>
              </div>
            </div>
          </AnimationOnScroll>
          <div className="project-details-image">
            {project.images.body ? <><img src={body.image} alt="complex" /></> : <></>}
            <LazyLoadComponent>
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                <div className="project-details-image-text">
                  <p>{project.description.p1 ? project.description.p1 : ''}</p>

                  <p>{project.description.p2 ? project.description.p2 : ''} </p>

                  <p>{project.description.p3 ? project.description.p3 : ''}</p>
                </div>
              </AnimationOnScroll>
            </LazyLoadComponent>
          </div>
          {project?.award ? (
            <div className="project-details-awards">
            <span className="project-details-awards-year">
              {project?.award?.year || ''}
            </span>
              <span>
              {project?.award?.title ? <>{project.award.title}</> : <></>}
                {project?.award?.enTitle ? (
                  <>
                    <br />
                    {project.award.enTitle}
                  </>
                ) : (
                  <></>
                )}
                {project?.award?.name ? (
                  <>
                    <br />
                    <b>{project?.award?.name || ''}</b>
                  </>
                ) : (
                  <></>
                )}
            </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        {slider.length > 0 ? (
          <div className="project-details-slider">
            <CCarousel autoSlide={5000} animate={true}>
              <div
                style={{
                  height: '100%',
                  width: '10%',
                  backgroundColor: 'black',
                }}
              >
                <CCarouselControl direction="next" className="slider-control" />
                <CCarouselControl direction="prev" className="slider-control" />
              </div>
              <CCarouselInner className="project-details-slider-inner">
                {slider.map((slide, index) => (
                  <CCarouselItem
                    className="project-details-slider-item"
                    key={`slide-${index}`}
                  >
                    <ProjectSliderItem slide={slide}/>
                  </CCarouselItem>
                ))}
              </CCarouselInner>
              <div className="indicators-container">
                <CCarouselIndicators className="carousel-atta-indicators" />
              </div>
            </CCarousel>
          </div>
        ) : (
          <></>
        )}
        {project.video ? (
          <div style={{ maxHeight: '600px', height: '600px' }}>
            <ReactPlayer
              style={{ margin: '0 auto' }}
              width="80%"
              height="100%"
              url={project.video}
              controls={true}
            />
          </div>
        ) : (
          <></>
        )}
        <OtherProjects otherProjects={cProjects}/>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Footer />
      </AnimationOnScroll>
      </div>
    ) : <Loader />
  );
};
