import { CCarousel, CCarouselIndicators, CCarouselInner, CCarouselItem } from '@coreui/react';
import React from 'react';
import { OtherProjectsItem } from './OtherProjectsItem';

export const OtherProjects = ({ otherProjects }) => {
  return (
    <div className="article-details-other">
      <div className="article-details-other-title">
        <span>Похожие проекты</span>
      </div>
      {otherProjects.length > 1 ? (<CCarousel animate={true}>
        <CCarouselInner className="article-details-slider-inner">
          {otherProjects.map(projects => {
            return (
              <CCarouselItem>
                <div className="pr-details-slider-item">
                  {projects.map(project => <OtherProjectsItem project={project}/>)}
                </div>
              </CCarouselItem>
            )
          })}
        </CCarouselInner>
        <div className="indicators-container">
          <CCarouselIndicators className="carousel-atta-indicators-news" />
        </div>
      </CCarousel>) : <></>}
    </div>
  )
}