import React, { useEffect, useState } from 'react';

import CareerContainer from '../components/CareerContainer.component';
import Footer from '../../../components/footer/Footer.component';
import Vacancy from '../components/CareerVacancy.component';
import { Loader } from '../../../components/loading/Loader';
import { getCareers } from '../../../data/useCareers'

const CareerPage = () => {
  const careersData = getCareers()
  const [careers, setCareers] = useState(careersData)

  return (
    <>
      {careers.length > 0 ? <>
        <div className="career">
          <CareerContainer />
        </div>
        <div className="vacancies">
          <div>
            <Vacancy careers={careers} />
          </div>
        </div>
        <div className="career">
          <Footer />
        </div>
      </> : <Loader />}
    </>
  )
};

export default CareerPage;
