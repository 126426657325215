const data = {
  text_header:
    'Группа компаний «АТТА» объединяет компании, специализирующиеся на проектировании и  консалтинге.',
  text_p1: 'Стабильность, надежность. (более 30лет на рынке)',

  text_p2: 'Широкая география проектов.',
  text_p3: 'Функциональное разнообразие проектов.',
  text_p4: 'Комплексный подход к проектированию. Проект "под ключ"',
  text_p5: 'Собственный штат специалистов по всем разделам проекта. Кросфункциональные команды и партнеры.',
  text_p6: 'Собственная оригинальная и эффективная система управления проектами.',
  text_p7: 'Широкий круг заказчиков, в том числе постоянных.',
  text_p8: 'Наши проекты материализуются в объекты, отмеченные Российскими и международными премиями.',
};

export default data;
