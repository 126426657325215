import React, { useEffect, useState } from 'react';

import './Slider.component.scss';
import {
  CCarousel,
  CCarouselControl,
  CCarouselInner,
  CCarouselIndicators,
  CCarouselCaption,
  CCarouselItem,
} from '@coreui/react';
import { Loader } from '../loading/Loader';
import { getMainSlider } from '../../data/useMain';
import { Slide } from './Slide';

function Slider() {
  const slidesData = getMainSlider()
  const [slides, setSlides] = useState(slidesData)

  return (
    <div className="intro">
      <div className="intro-container">
        <div className="intro-inner">
          { slides.length > 0 ?
            <CCarousel
              className="slider"
              autoSlide={10000}
              animate={true}
              activeIndex={0}
            >
              <div className="slider-indicators">
                <CCarouselIndicators />
              </div>
              <CCarouselInner>
                {slides.map((slide) => (
                  <CCarouselItem key={slide._id}>
                    <Slide slide={slide}/>
                  </CCarouselItem>
                ))}
              </CCarouselInner>
              <div
                style={{ height: '100%', width: '10%', backgroundColor: 'black' }}
              >
                <CCarouselControl direction="prev" className="slider-control" />
                <CCarouselControl direction="next" className="slider-control" />
              </div>
            </CCarousel>
            : <><Loader /></> }
        </div>
      </div>
    </div>
  );
}

export default Slider;
