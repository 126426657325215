import React from 'react';

import Footer from '../../components/footer/Footer.component';
import { ProjectsContainer } from '../../modules/Projects/containers/ProjectsContainer';

const Projects = () => (
  <>
    <div className="projects">
      <div className="containerM">
        <div className="projects_inner" style={{ width: '95%', margin: '0 auto' }}>
          <h2 className="header_title">
            Проекты
          </h2>
          <div className="projects-container">
            <ProjectsContainer />
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </>
);

export default Projects;
