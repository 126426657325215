import React from 'react';

import AboutContainer from '../components/AboutContainer.component';
import Footer from '../../../components/footer/Footer.component';

const AboutPage = () => (
  <>
    <div className="about" style={{ marginTop: '100px', width: '95%' }}>
      <AboutContainer />
    </div>
    <div className="about">
      <Footer />
    </div>
  </>
);

export default AboutPage;
