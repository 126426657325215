import React, { useState } from 'react';
import ReactQuill from 'react-quill';

import {
  CCollapse,
  CCard,
  CCardHeader,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CButton,
  CInput,
} from '@coreui/react';
import doc from '../../../img/google-docs.png';
import chevronDown from '../../../img/slider/chevron-down.svg';
import chevronUp from '../../../img/slider/chevron-up.svg';

const Vacancy = ({ careers }) => {
  const [collapse, setCollapse] = useState({});
  const [isActive, setActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeVacancy, setActiveVacancy] = useState({});

  const showModal = (vac = {}) => {
    setActiveVacancy(vac);
    setModal(!modal);
  };

  const toggle = (id) => {
    const map = collapse || {};
    map[id] = !map[id];

    setCollapse(collapse);
    setActive(!isActive);
  };

  const mapVacancies = () => {
    if (careers && careers.length > 0) {
      return careers.map((vac) => (
        <CCard className="vacancies-inner rounded-0" style={{ width: '100%' }}>
          <CCardHeader
            className="vac-header rounded-0"
            onClick={() => toggle(vac._id)}
          >
            <span className="vac-title">{vac.title}</span>
            <img
              src={collapse[vac._id] ? chevronDown : chevronUp}
              className={
                collapse[vac._id]
                  ? 'icon-atta float-right'
                  : 'icon-atta-active float-right atta-green'
              }
              alt="arrow"
            />
          </CCardHeader>
          <CCollapse show={collapse[vac._id]} className="font-sm">
            <ReactQuill
              theme="snow"
              value={vac.description}
              modules={{ toolbar: false }}
              className="quillEditor"
              readOnly
            />
            <hr />
            <div className="career-button" onClick={() => showModal(vac)}>
              <span>Отправить резюме</span>
            </div>
          </CCollapse>
        </CCard>
      ));
    }

    return <div className="vacancies-inner">No vacancies now!</div>;
  };

  return (
    <>
      <CModal show={modal} onClose={() => showModal()}>
        <CModalHeader>
          <CModalTitle>{activeVacancy.title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <span style={{ fontSize: '1rem' }}>Ждем ваши резюме: <a href="mailto:atta@atta-group.ru">atta@atta-group.ru</a></span>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => showModal()}>
            Закрыть
          </CButton>
        </CModalFooter>
      </CModal>
      <div className="vacancies-inner">{mapVacancies()}</div>
    </>
  );
};

export default Vacancy;
