import React, { useEffect, useState } from 'react';

import { Project } from './Project';
import { Loader } from '../../../components/loading/Loader';
import { useParams } from 'react-router-dom';
import { pTypes } from '../constants';
import {getProjects} from '../../../data/useProjects';


export const FilteredProjectsList = () => {
  const [projects, setProjects] = useState([])
  const params  = useParams()
  const { category } = pTypes.find(type => type.title === params.category)

  useEffect(() => {
    setProjects(getProjects(category))

    return () => {
      setProjects([])
    }
  }, [category]);

  return (
    <>
      {projects.length < 1 ? (
        <Loader />
      ) : (
        <div className="projects-list">
          {projects.map((project) => (
            <div className="project">
              <Project project={project} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
