import React from 'react';
import { useImage } from '../../../data/useImage';
import { useHistory } from 'react-router-dom';

export const OtherProjectsItem = ({project}) => {
  const history = useHistory()
  const style = { fontSize: '0.8em', fontWeight: 'bold' };
  const { image } = useImage(project.images.header)
  return (
    <div className="prItem">
      <div
        className="article-item"
        style={{
          background: `url(${image}) center center / cover no-repeat`,
          backgroundSize: 'cover',
        }}
        onClick={() => {
          history.push(`/projects/project/${project._id.$oid}`);
        }}
      >
                            <span className="article-item-title">
                              <span>
                                <h6 style={project.name.length > 40 ? style : { fontWeight: 'bold' }}>{project.name}</h6>
                            </span>
                            </span>
      </div>
    </div>
  )
}