import React from 'react';
import Slider from '../../components/slider/Slider.component';

const Main = () => (
  <div>
    <div>
      <Slider />
    </div>
  </div>
);

export default Main;
