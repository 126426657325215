import { projectTypes } from '../types/projects';

const {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_STARTED,
} = projectTypes;

const initialState = {
  loading: false,
  projects: [],
  error: null,
};

export function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        projects: action.payload,
      };
    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
