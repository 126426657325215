import React from 'react';
import { News } from './News';

export const NewsList = ({ news }) => {
  return (
    <>
      <div className="news-list">
        {news.map((item) => (
          <News article={item} />
        ))}
      </div>
    </>
  );
};
