import React from 'react';

import { ContactsContainer } from '../../modules/Contacts/containers/Contacts.container';
import Footer from '../../components/footer/Footer.component';

const Contact = () => (
  <div>
    <div className="contact">
      <div className="containerM" style={{ marginTop: '100px', width: '95%' }}>
        <ContactsContainer />
      </div>
    </div>
    <div className="career">
      <Footer />
    </div>
  </div>
);

export default Contact;
