import React from 'react';
import { useImage } from '../../../data/useImage';
import { useHistory } from 'react-router-dom';

const LArticle = ({a}) => {
  const { image } = useImage(a.header)
  const history = useHistory()
  const style = { fontSize: '0.8em', fontWeight: 'bold' };
  return (
    <div className="article">
      <div
        className="article-item"
        style={{
          background: `url(${image}) center center / cover no-repeat`,
          backgroundSize: 'cover',
        }}
        onClick={() => {
          history.push(`/news/${a._id.$oid}`);
        }}
      >
        <span className="article-item-title">
          <span>
            <h6 style={a.title.length > 40 ? style : { fontWeight: 'bold' }}>{a.title}</h6>
            <p style={{ fontSize: '16px' }} className="article-info-short">{(a.short.length > 150 ? `${a.short.slice(0, 150)}...` : a.short) || ''}</p>
         </span>
        </span>
      </div>
    </div>
  )
}

export const LatestArticle = ({ article }) => {
  return (
    <div className="article-details-slider-item">
      {article.map(a => <LArticle a={a} />)}
    </div>
  )
}