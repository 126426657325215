import { combineReducers } from 'redux';

import { newsReducer } from './news';
import { careersReducer } from './careers';
import { projectsReducer } from './projects';

export const rootReducer = combineReducers({
  news: newsReducer,
  careers: careersReducer,
  projects: projectsReducer,
});
