import { newsTypes } from '../types/news';

const { GET_NEWS_STARTED, GET_NEWS_FAILURE, GET_NEWS_SUCCESS } = newsTypes;

const initialState = {
  loading: false,
  news: [],
  error: null,
};

export function newsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        news: action.payload,
      };
    case GET_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
