import { CCarouselItem } from '@coreui/react';
import React from 'react';
import { useImageAward } from '../../../data/useImage';
import { Loader } from '../../../components/loading/Loader';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const AwardSlide = ({award}) => {
  const { image } = useImageAward(award.image)
  const style = { fontSize: '0.8em', fontWeight: 'bold' };

  return (
    <div className='award'>
      <LazyLoadComponent>
        {image ? (
          <div
            className="award-item"
            style={{
              background: `url(${image}) center center / cover no-repeat`,
              backgroundSize: 'cover',
            }}
            title={award.signature}
          >
        <span className="award-item-title">
          <span style={award.signature.length > 30 ? style : { fontWeight: 'bold' }}>{award.signature}</span>
        </span>
          </div>
        ) : <Loader />}
      </LazyLoadComponent>
    </div>
  )
}

export const AwardItemComponent = ({item}) => {
  return (
    <CCarouselItem>
      <div className='awards' key={item.signature}>
        {item.map((award) => (
          <AwardSlide award={award}/>
        ))}
      </div>
    </CCarouselItem>
  )
}