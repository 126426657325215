import awards from '../../../img/About/award';

const data = [
  {
    class: 'award-inner-item-img-wrap',
    image: 'BestEnterprise.jpg',
    text:
      '1Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: '«Best Enterprise»',
  },
  {
    class: 'award-inner-item-img-wrap',
    image: 'EngineersofNEWERA.jpg',
    text:
      '2Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Engineers of NEW ERA',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'diplomatta.jpg',
    text:
      '3Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Диплом АТТА',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'diplom.jpg',
    text:
      '3Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Диплом от Мэра',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'eustandart.jpg',
    text:
      '5Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Европейский стандарт',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'euberlin.jpg',
    text:
      '5Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Европейский стандарт Берлин',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'businessaward.jpg',
    text:
      '5Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'За успешное развитие бизнеса в Сибири',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'architectureaward.jpg',
    text:
      '5Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Качественная Архитектура',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'newprize.jpg',
    text:
      '5Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'НОПРИЗ',
  },
  {
    class: 'award-inner-item-img-wrap award-img-wrap3',
    image: 'swisspartners.jpg',
    text:
      '5Награда Европейской Бизнес Ассамблеи (Europe Business Assembly, Оксфорд, Великоб' +
      'ритания) в области развития и управления «BEST ENTERPRISE» («Лучшее предприятие»' +
      ') в сфере архитектурного и градостроительного проектирования и включение в реест' +
      'р конкурентоспособных предприятий и надежных бизнес-партнеров',
    signature: 'Специальное партнерство Швейцария',
  },
];

export default data;
