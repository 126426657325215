import React from 'react';
import { useHistory } from 'react-router-dom';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useImage } from '../../../data/useImage';

export const Project = ({ project }) => {
  const history = useHistory();
  const { image } = useImage(project.images.header)
  return (
    <AnimationOnScroll
      animateIn="animate__fadeInUp"
      animateOnce={true}
      style={{ overflow: 'hidden' }}
    >
      <div
        className="project-item"
        style={{
          background: `url(${image}) center center / cover no-repeat`,
          backgroundSize: 'cover',
        }}
        title={project.name}
        onClick={() => history.push(`project/${project._id.$oid}`)}
      >
        <span className="project-item-title">
          <span>{project.name}</span>
        </span>
      </div>
    </AnimationOnScroll>
  );
};
