import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Main from './pages/Main/Main';
import Projects from './pages/Projects/Projects';
import CategoryProjects from './pages/Projects/CategoryProjects';
import News from './pages/News/News';
import Contact from './pages/Contact/Contact';
import Career from './pages/Career/Career';
import About from './pages/About/About';

import './styles/index.scss';
//Quill
import 'react-quill/dist/quill.snow.css';
import { ProjectDetails } from './modules/Projects/components/ProjectDetails';
import ScrollToTop from './components/ScrollToTop';
import { Article } from './modules/News/components/Article';
import { Navbar } from './pages/Main/Navbar';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/news" exact component={News} />
          <Route path="/news/:articleId" component={Article} />
          <Route path="/contacts" component={Contact} />
          <Route path="/career" component={Career} />
          <Route path="/about" component={About} />
          <Route
            path="/projects/:category"
            exact
            component={CategoryProjects}
          />
          <Route path="/projects/project/:name" exact component={ProjectDetails} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
